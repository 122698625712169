var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showNavigation
        ? [
            _c(
              "CButtonGroup",
              { staticClass: "mb-4" },
              [
                _c(
                  "CButton",
                  {
                    staticClass: "shadow-none button-custom text-white",
                    attrs: {
                      color: "scroll-handler",
                      variant:
                        _vm.$route.name === "mine-referrals" ? "" : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({ name: "mine-referrals" })
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("lk.referrals.navigation.mine")) + " "
                    ),
                  ]
                ),
                _c(
                  "CButton",
                  {
                    staticClass: "shadow-none button-custom text-white",
                    attrs: {
                      color: "scroll-handler",
                      variant:
                        _vm.$route.name === "lk-admin-bonus-transfer"
                          ? ""
                          : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "lk-admin-bonus-transfer",
                        })
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("lk.referrals.navigation.bonusTransfer")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "CButton",
                  {
                    staticClass: "shadow-none button-custom text-white",
                    attrs: {
                      color: "scroll-handler",
                      variant:
                        _vm.$route.name === "lk-admin-referrals-list"
                          ? ""
                          : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "lk-admin-referrals-list",
                        })
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lk.referrals.navigation.refList")) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("router-view"),
      _vm.isBrave
        ? _c("portal-target", { attrs: { name: "brave-notify-modal" } })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }