<template>
  <div>
    <template v-if="showNavigation">
      <CButtonGroup class="mb-4">
        <CButton
          color="scroll-handler"
          class="shadow-none button-custom text-white"
          @click="$router.push({name: 'mine-referrals'})"
          :variant="$route.name === 'mine-referrals' ? '' : 'outline'"
        >
          {{ $t('lk.referrals.navigation.mine') }}
        </CButton>
        <CButton
          color="scroll-handler"
          class="shadow-none button-custom text-white"
          @click="$router.push({name: 'lk-admin-bonus-transfer'})"
          :variant="$route.name === 'lk-admin-bonus-transfer' ? '' : 'outline'"
        >
          {{ $t('lk.referrals.navigation.bonusTransfer') }}
        </CButton>
        <CButton
          color="scroll-handler"
          class="shadow-none button-custom text-white"
          @click="$router.push({name: 'lk-admin-referrals-list'})"
          :variant="$route.name === 'lk-admin-referrals-list' ? '' : 'outline'"
        >
          {{ $t('lk.referrals.navigation.refList') }}
        </CButton>
      </CButtonGroup>
    </template>
    <router-view/>

    <portal-target v-if="isBrave" name="brave-notify-modal"/>
  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";

export default {
  name: "PageLkReferralsLayout",
  computed: {
    ...mapGetters('user', ['getPermissions']),
    ...mapState({
      isBrave: state => state.browserNotify.isBrave,
    }),
    showNavigation() {
      return this.getPermissions('view-page-admin-bonus-transfer')
    }
  }
}
</script>

<style scoped>

</style>
